'use strict';

angular.module('cpformplastApp.clients')
  .config(function ($stateProvider) {
    $stateProvider
      .state('clients/client-management', {
        url: '/clients/client-management',
        templateUrl: 'app/clients/client-management/client-management.html',
        controller: 'clientManagementController',
        authenticate: ['admin','administration_dept','management_dept','logistic_dept', "receptionist"]
      })
      .state('clients/client-management/client-creation', {
        url: '/clients/client-management/client-creation/:clientId',
        templateUrl: 'app/clients/client-management/client-creation/client-creation.html',
        controller: 'clientCreationController',
        authenticate: ['admin','administration_dept','management_dept','logistic_dept', "receptionist"]
      })
  });
